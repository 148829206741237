// Change bootstrap variables here
$primary: #126d86;
$secondary: #acacac;
$light: #e7e7e7;

// Importing Bootstrap
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600&display=swap");

.btn-primary {
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    
  }
}

.main {
  margin-top: 59px;
}

.text- {
  &small {
    font-size: 0.95rem;
  }

  &smaller {
    font-size: 0.85rem;
  }
}
.mt-6 {
  margin-top: 4rem;
}
.mt-7 {
  margin-top: 5rem;
}
.editableText {
  box-sizing: border-box;
  margin: 0.5rem 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: inherit;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  width: 100%;
  height: 30px;
  text-align: start;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: 0;
  appearance: textfield;
  vertical-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important; 
}

.overflow-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important; 
}

.editableText:hover {
  border: 1px solid $light;
  padding: 0 11px;
  transition: all 0.2s linear;
}

.no-outline {
  outline: none;
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
}

.non-focus-btn:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: auto !important;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // background: url('https://icon-library.com/images/spinner-icon-gif/spinner-icon-gif-19.jpg') 
  background: url('../src/loaderGif.gif') 
    50% 50% no-repeat;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2); 
    }
}

/* Works for Chrome, Safari, Edge, Opera */
.no-spinner input::-webkit-outer-spin-button,
.no-spinner input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
.no-spinner input[type="number"] {
  -moz-appearance: textfield;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.ant-picker-dropdown {
  position: relative;
  z-index: 9999;
}

.ant-picker-panel-container{
  width: fit-content;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-typography+h5.ant-typography {
  margin-top: 9px;
}

.ant-btn {
  display: inline-flex;
  align-items: center;
}

.dropBox {
  padding: 1rem;
  margin-top: 0.8rem;
}

.btn-width{
  min-width: 16%;
}
.dropBox_upload_title {
  text-align: center;
  margin-top: 20vh;
  text-decoration: none;
  word-spacing: 1px;
  font-weight: 700;
}

.dropBox_btn{
  text-align: center;
}

.decoration-none, .decoration-none:hover{
  text-decoration: none;
  color: #ffffff;
}


.ant-upload-drag-icon,
.ant-upload-text,
.ant-upload-hint {
  color: #1890ff; 
}

.ant-upload-drag-icon {
  font-size: 48px;
}

.ant-upload-text {
  margin-top: 16px;
  font-weight: bold;
}

.ant-upload-hint {
  margin-top: 8px;
  font-size: 14px;
}

.search-form {
  width: 50%;
}

.vh-65 {
  height: 65vh;
}

@media (max-width: 680px) {
  .header-nav {
    flex-direction: column !important;
  }

  .search-form {
    width: 100%;
  }

  .dropdown-search {
    position: fixed;
    top: 0px;
    right: 1rem;
  }
}

.btn-custom {
  padding: 8px 10px; 
  font-size: 16px;
  border-radius: 0.5rem;
  width: 15rem; 
  max-width: 100%; 
  display: inline-block; 
}

@media (max-width: 600px) {
  .btn-custom {
      padding: 8px 16px; 
      font-size: 14px;
      width: auto; 
  }
}

.ant-divider {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}